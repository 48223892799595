<template>
  <div id="theme-top" class="top-left">
    <div class="top-head" :style="this.theme == 'white' ? `width:${width-18}px` :'width:100%'">
      <el-breadcrumb>
        <el-breadcrumb-item
          v-for="item in breadArr"
          :key="item.title"
          :to="{ path: item.path }"
        >{{ item.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="right-menu">
        <!-- <el-badge v-if="unreadMessageWarnList.length" is-dot class="messageIcon-badge">
          <i id="messageIcon-i" class="iconfont icon-xiaoxitixing" @click="clickItemMessage"></i>
        </el-badge>
        <i v-else id="messageIcon-i" class="iconfont icon-xiaoxitixing" @click="clickItemMessage"></i> -->
        <el-dropdown trigger="click">
          <el-badge :is-dot="unreadMessageWarnList.length ? true :false" class="messageIcon-badge">
            <i id="messageIcon-i" class="iconfont icon-xiaoxitixing" @click="clickItemMessage"></i>
          </el-badge>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span style="display:block;" @click="clickMessage">消息查看</span>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span style="display:block;" @click="clickSetMessage">消息设置</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <Myswitch @change="themeChange" v-model="switchthemeValue" openText="☾" closeText="☼" class="my-switch"></Myswitch> -->
        <el-dropdown trigger="click">
          <div class="avatar-container" @click="clickAvatar">
            <img v-if="ifAvatar" src="../../assets/imgs/indexPng/avatar.png" alt="头像" />
            <div v-else class="img-none"></div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span style="display:block;" @click="jumPersonal">个人中心</span>
            </el-dropdown-item>
            <el-dropdown-item divided v-if="financeDept">
              <span style="display:block;" @click="exchangeRate">汇率管理</span>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span style="display:block;" @click="themeChange">切换主题</span>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span style="display:block;" @click="open">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="top-bottem" :style="`width:100%`">
      <div class="tabbar fix-nav" id="navA" ref="navA" :style="`width:${width-20}px`">
        <div class="tab_title" v-for="(tag, index) in tagArr" :key="index">
          <div class="tab" ref="tabcolor" @click="handletab(index)">
            <router-link
              :id="tag.title"
              :key="tag.title"
              closable
              :disable-transitions="false"
              @click.middle.native="handleClick(tag,index)"
              :class="isActive == tag.title ? 'tag-active' :''"
              :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
            >
              <i :class="isActive == tag.title ? 'tag-active-i' :''"></i>
              {{tag.title}}
              <i
                @click.stop.prevent="handleClose(tag)"
                :class="tag.title !='首页' ? 'tag-active-close' :''"
              ></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗部分 -->
    <!-- 汇率 -->
    <el-dialog
      title="汇率管理"
      :visible.sync="dialograteVisible"
      width="640px"
      :before-close="handlerateClose">
      <div class="rate-manage">
    <p class="current-rate">
      <el-divider content-position="left">当前({{  new Date().getMonth() + 1 }})月的汇率数据</el-divider>
    </p>
    <ul class="order-rate">
        <li v-for="(item,index) in rateList" :key="index">
          <p>
            <span class="rate-label">币种:</span>
            <el-select class="rate-input" clearable v-model="item.currency" placeholder="请选择" @change="currencyChange(index)">
              <el-option
                v-for="item in CurrencyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </p>
          <p>
            <span class="rate-label">当前汇率:</span>
            <el-input class="rate-input" clearable v-input.float v-model="item.rate" autocomplete="off"></el-input>
          </p>
          <p class="p-button">
            <el-button
              v-preventReClick
              @click="addRateType(index)"
              type="primary"
              icon="el-icon-plus"
              circle
            ></el-button>
            <el-button
              v-preventReClick
              :disabled="rateList.length ==1"
              @click="delRateType(index)"
              type="danger"
              icon="el-icon-minus"
              circle
            ></el-button>
          </p>
        </li>
      </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-preventReClick @click="dialograteVisible = false">取 消</el-button>
        <el-button v-preventReClick type="primary" @click="submitRate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 消息设置 -->
    <el-dialog
      title="消息设置"
      :visible.sync="dialogMessageSetVisible"
      width="640px">
      <div class="message-set-manage">
        <div class="message-type-title" v-for="(item,index) in userMessageTemplateList" :key="item.id">
          <span style="margin-right: 8px;display: block;width: 24px;">{{ index+1 }}. </span>
          <el-checkbox-group v-model="item.messageTypeCheckList" @change="handleCheckedMessageChange(index)">
            <el-checkbox :disabled="item.isMessageEmailDisabled" label="邮箱订阅"></el-checkbox>
            <el-checkbox :disabled="item.isMessageSiteDisabled" label="站内信订阅"></el-checkbox>
          </el-checkbox-group>
          <p>模板主题：{{ item.template ? item.template.topicName :"" }}</p>
        </div>
      </div>
      <div :class="theme == 'white' ? 'page-box' :'blue-page-box'">
        <el-pagination
          hide-on-single-page
          @size-change="messageHandleSizeChange"
          @current-change="messageHandleCurrentChange"
          :current-page="messageCurrentPage"
          :page-size="messagePageSize"
          layout="total,prev, pager, next"
          :total="messageTotal">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 消息提醒 -->
    <MessageWarn ref="messageWarnComponents"
     :messageWarnList="messageWarnList" 
     :unreadMessageWarnList="unreadMessageWarnList" 
     :readMessageWarnList="readMessageWarnList"
     @changePagesize="changePagesize"
     @changeCurrent="changeCurrent"
    />
  </div>
</template>
  
<script>
import local from "@/utils/local";
import store from "@/store";
import method from "@/utils/method";
import Myswitch from "@/components/switch/Myswitch.vue";
import MessageWarn from "@/components/drawers/MessageWarn.vue";
import { api_logout,api_rateCurrent,api_addRate,api_updateRate } from "@/apis/login";
import { api_getdictDetailMap } from "@/apis/system/dict";
import { api_getMessageWarnPage,api_updatemessageSubscribe,api_messageSubscribePage } from '@/apis/messageWarn'

export default {
  components:{
    MessageWarn,
    Myswitch
  },
  data() {
    return {
      inThrottle:false,
      messageCurrentPage:0,
      messagePageSize:10,
      messageTotal:10,
      userMessageTemplateList:[],
      messageTypeCheckList:[],//消息订阅
      subscribe:{
        id:null,
        userId:null,
        subscribeEmail:null,
        subscribeSite:null,
        template:null
      },//订阅信息
      // switchthemeValue:false,
      width: document.body.offsetWidth - 324,
      ifAvatar: true,
      breadArr: [], // 面包屑的数组
      isActive: "",
      tagArr: [{ path: "/home", title: "首页" }],
      changeRed: "0",
      theme: "white",
      financeDept:false,//汇率管理
      dialograteVisible:false,
      dialogMessageSetVisible:false,
      messageWarnList:[],//消息集合
      unreadMessageWarnList:[],//未读
      readMessageWarnList:[],//未读
      rateList:[{
        currency:"",
        rate:""
      }],
      CurrencyList:[],
      currentRateId:"",
      totalElements:1,
      currentPage:0,
      themetimeout:null,
    };
  },
  methods: {
    themeChange(val) {
      clearTimeout(this.themetimeout);
      console.log("切换后的状态themeChange", val);
      console.log(this.theme,'this.theme======================');
      this.themetimeout = setTimeout(() => {
        if(this.theme == 'white'){
          this.theme = 'blue';
        }else{
          this.theme = 'white';
        }
        local.set("theme", this.theme);
        let app = document.getElementById("app");
        let menu = document.getElementById("theme-menu");
        let element = document.getElementById("theme-top");
        let search = document.getElementById("theme-search");
        let layout = document.getElementById("theme-layout");
        let bottom = document.getElementById("right-bottom");
        let tableHeight = document.getElementById("tableHeight");
        let pageBox = document.getElementById("pageBox");
        let drop = document.getElementsByClassName("el-dropdown-menu");
        if(drop.length){
          for (let i = 0; i < drop.length; i++) {
            if (this.theme == "blue") {
              drop[i].classList.add("blue-dropDown-menu");
            } else {
              drop[i].classList.remove("blue-dropDown-menu");
            }
          }
        }
        if (this.theme == "blue") {
          app ? app.classList.add("blue") :null;
          app ? app.classList.remove("white") :null;
          element ? element.classList.add("blue-top-left") :null;
          element ? element.classList.remove("top-left"):null;
          menu ? menu.classList.add("blue-left-meanu"):null;
          menu ? menu.classList.remove("left-meanu"):null;
          search ? search.classList.add("blue-search-box"):null;
          search ? search.classList.remove("search-box"):null;
          layout ? layout.classList.add("blue-layout"):null;
          layout ? layout.classList.remove("layout"):null;
          bottom.childNodes[0] ? bottom.childNodes[0].classList.add("blue-mag-user"):null;
          bottom.childNodes[0] ? bottom.childNodes[0].classList.remove("mag-user"):null;
          tableHeight ? tableHeight.classList.add("blue-table-box") : null;
          tableHeight ? tableHeight.classList.remove("table-box") : null;
          pageBox ? pageBox.classList.add("blue-page-box"):null;
          pageBox ? pageBox.classList.remove("page-box"):null;
        } else {
          app ? app.classList.add("white") :null;
          app ? app.classList.remove("blue") :null;
          element ? element.classList.add("top-left"):null;
          element ? element.classList.remove("blue-top-left") :null;
          menu ? menu.classList.add("left-meanu"):null;
          menu ? menu.classList.remove("blue-left-meanu"):null;
          search ? search.classList.add("search-box"):null;
          search ? search.classList.remove("blue-search-box"):null;
          layout ? layout.classList.add("layout"):null;
          layout ? layout.classList.remove("blue-layout"):null;
          bottom.childNodes[0] ? bottom.childNodes[0].classList.add("mag-user"):null;
          bottom.childNodes[0] ? bottom.childNodes[0].classList.remove("blue-mag-user"):null;
          tableHeight ? tableHeight.classList.add("table-box") : null;
          tableHeight ? tableHeight.classList.remove("blue-table-box") : null;
          pageBox ? pageBox.classList.add("page-box"):null;
          pageBox ? pageBox.classList.remove("blue-page-box"):null;
        }
      }, 200)
    },
    messageHandleSizeChange(val){
      console.log(`每页 ${val} 条`);
    },
    messageHandleCurrentChange(val){
      console.log(`当前页: ${val}`);
      this.messageCurrentPage = val;
      this.clickSetMessage()
    },
    //订阅选项更改
    async handleCheckedMessageChange(index){
      // if (!this.inThrottle) {
        if(this.userMessageTemplateList[index].messageTypeCheckList.indexOf('邮箱订阅') != -1){
          this.userMessageTemplateList[index].subscribeEmail = 1
        }else{
          this.userMessageTemplateList[index].subscribeEmail = 0
        }
        if(this.userMessageTemplateList[index].messageTypeCheckList.indexOf('站内信订阅') != -1){
          this.userMessageTemplateList[index].subscribeSite = 1
        }else{
          this.userMessageTemplateList[index].subscribeSite = 0
        }
        console.log(this.userMessageTemplateList[index],'订阅选择改变');
        let {code,msg} =await api_updatemessageSubscribe(this.userMessageTemplateList[index]);
        if(code == 200){
          this.$message.success('修改成功')
        }else{
          this.$message.error(msg);
        }
        // this.inThrottle = true; // 标记为正在节流
        // console.log(this);
        // setTimeout(() => {
        //   this.inThrottle = false; // 重置节流状态
        // }, 1000);
      // }
    },
    async clickSetMessage(){
      this.subscribe.userId = local.get('userId');
      let pageNum = JSON.parse(JSON.stringify(this.messageCurrentPage)) ;
      let parameter = {
        userId:local.get('userId'),
        pageNum: pageNum ? pageNum -1: 0,
        pageSize:this.messagePageSize,
      }
      console.log(parameter,'parameter');
      let {code,msg,data} = await api_messageSubscribePage(parameter);
      if(code == 200){
        this.userMessageTemplateList = data.content;
        this.userMessageTemplateList.forEach(item =>{
          if(item.template && item.template.isSupportEmail === 0 ){
            item.isMessageEmailDisabled = true;
          }else{
            item.isMessageEmailDisabled =false;
          }
          if(item.template && item.template.isSupportSite === 0 ){
            item.isMessageSiteDisabled = true;
          }else{
            item.isMessageSiteDisabled=false;
          }
          let messageTypeCheckList = [];
          if(item.subscribeEmail === 1){
            messageTypeCheckList.push('邮箱订阅');
          }
          if(item.subscribeSite === 1){
            messageTypeCheckList.push('站内信订阅');
          }
          this.$set(item, 'messageTypeCheckList', messageTypeCheckList);//在vue实例中挂载
        })
        console.log(this.userMessageTemplateList,'订阅消息内容');
        this.messageTotal = data.totalElements;
        this.messagePageSize = data.size;
        this.messageCurrentPage = data.number +1;
        this.dialogMessageSetVisible = true;
      }else{
        this.$message.error(msg);
      }
    },
    async clickMessage(){
      await this.getmessagePage(this.currentPage,10,'0')
      this.$nextTick(() => {
        let refs = this.$refs.messageWarnComponents
        refs.dialogMessageVisible = true;
        console.log(refs,'============refs===');
      })
    },
    changePagesize(currentPage, val,activeName){
      console.log(currentPage,val,activeName,'父组件中页码值===');
      if(activeName === '2'){
        activeName = null;
      }
      this.getmessagePage(currentPage,val,activeName);
    },
    changeCurrent(val, pagesize,activeName) {
      console.log(val,pagesize,activeName,'父组件中页码值===');
      if(activeName === '2'){
        activeName = null;
      }
      this.getmessagePage(val,pagesize,activeName);
    },
    //获取分页消息
    async getmessagePage(currentPage,pagesize,viewStatus){
      let parameter = {
        deleted: 0,
        isAsc: true,
        pageNum: currentPage ? currentPage : 0,
        pageSize: pagesize ? pagesize : 10,
        userId: local.get('userId'),
        viewStatus:viewStatus
      }
      let {data,msg,code} = await api_getMessageWarnPage(parameter)
      if(code == 200){
        this.currentPage = data.number;
        this.totalElements = data.totalElements;
        data.content.forEach(item => item.viewStatus = item.viewStatus == 0 ? '未读' : '已读');
        if(viewStatus === '0'){
          this.unreadMessageWarnList = data.content;
        }else if(viewStatus === '1'){
          this.readMessageWarnList = data.content;
        }else{
          this.messageWarnList = data.content;
        }
        console.log(this.messageWarnList,'messageWarnList===========');
        console.log(this.unreadMessageWarnList);
        console.log(this.readMessageWarnList);
        this.$nextTick(()=>{
          this.$refs.messageWarnComponents.total = data.totalElements;
          this.$refs.messageWarnComponents.currentPage = data.number+1;
        },500)
      }else{
        this.$message.error(msg);
      }
    },
    // -------以上为消息---------
    //获取当前月汇率
    async getRateCurrent(){
      let {data,code,msg} = await api_rateCurrent();
      if(code == 200){
        console.log(data,'当前月汇率信息');
        if(data == null){
          this.currentRateId = ""
          this.rateList = [{
            currency:"",
            rate:""
          }]
        }else{
          local.set('rate',data.rateJson);
          this.currentRateId = data.id;
          this.rateList = JSON.parse(data.rateJson)
        }
      }else{
        this.$message.error(msg)
      }
    },
    //币种列表
    async getCurrencyList() {
      let { data, code, msg } = await api_getdictDetailMap("currency");
      if (code == 200) {
        this.CurrencyList = data.currency;
      } else {
        this.$message.error(msg);
      }
    },
    currencyChange(index){
      this.rateList[index].rate = "";
    },
    addRateType(index){
      this.rateList.push({
        currency: "",
        rate: ""
      });
    },
    delRateType(index) {
      this.rateList.splice(index, 1);
    },
    //保存汇率
    async submitRate(){
      let Currency =  method.checkRepeat(this.rateList,'currency');
      if(Currency){
        return this.$message.error('存在重复币种，请重新填写！');
      };
      console.log('币种汇率：',this.rateList);
      let current = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
      console.log(this.currentRateId);
      if(this.currentRateId){
          let parameter = {
            id:this.currentRateId,
          current:current,
          rateJson:JSON.stringify(this.rateList)
        }
        let {code,msg} = await api_updateRate(parameter);
        if(code == 200){
          this.$message({
            message: "保存成功！",
            type: "success"
          });
          this.dialograteVisible = false
        }else{
          this.$message.error(msg)
        }
      }else{
        //新增
          let parameter = {
          current:current,
          rateJson:JSON.stringify(this.rateList)
        }
        let {code,msg} = await api_addRate(parameter);
        if(code == 200){
          this.$message({
            message: "保存成功！",
            type: "success"
          });
          this.dialograteVisible = false
        }else{
          this.$message.error(msg)
        }
      }
      local.set('rate',JSON.stringify(this.rateList));
    },
    ///=========以上为币种======
    // 导航栏事件
    handletab(index) {
      this.changeRed = index; //赋值颜色
    },
    // 计算面包屑函数
    countBread() {
      let arr = [{ path: "/home", title: "首页" }]; // 定义一个空数组，用于接收push进来的hash和title
      let strLength = 0;
      this.$route.matched.forEach(item => {
        if (item.meta.title != undefined) {
          strLength = method.countCharOccurrences(item.path, "/");
          console.log("strLength:", strLength, item.meta.title);
          if (item.meta.title != "首页") {
            arr.push({
              path: item.path,
              title: item.meta.title
            });
          }

          if (strLength > "1") {
            this.tagArr.push({
              path: item.path,
              title: item.meta.title
            });
          }
        }
      });
      let temparr = [];
      temparr = method.ArrayObjectDeduplication(this.tagArr, "title");
      this.tagArr = temparr;
      this.breadArr = arr;
      console.log(this.breadArr);
    },
    handleClose(tag) {
      let newArr = this.tagArr;
      for (let i = 0; i < newArr.length; i++) {
        if (tag.title == newArr[i].title) {
          this.tagArr.splice(i, 1);
          console.log(this.isActive);
          if (this.isActive == tag.title) {
            // 2、删除最后一个，跳转到最后一个
            if (i == this.tagArr.length) {
              this.$router.push(this.tagArr[i - 1].path);
            } else {
              // 3、删除的是自己，跳转到下一个
              this.$router.push(this.tagArr[i].path);
            }
          } else {
            document.getElementById(tag.title).onclick = function(e) {
              e.preventDefault();
            };
          }
        }
      }
    },
    handleClick(tag) {
      console.log(tag);
      for (let i = 0; i < this.tagArr.length; i++) {
        if (tag.title == this.tagArr[i].title) {
          this.isActive = tag.title;
          this.handletab(i);
        }
      }
      console.log("点击激活状态：", this.isActive);
    },
    //页面刷新
    beforeunload() {
      local.set("tagArr", JSON.stringify(this.tagArr));
    },
    getnewurl(){
      let url =document.location.href;
      if (url.indexOf('?') != -1) {
        url = url.split("?")[0];
      }else{
        if(url.indexOf('#') != -1){
          url = url.split("#")[0];
        }
      }
      return url
    },
    //点击消息
    clickItemMessage(){
      this.$nextTick(() => {
        let drop = document.getElementsByClassName("el-dropdown-menu"); 
        if(drop.length){
          for (let i = 0; i < drop.length; i++) {
            if (local.get("theme") == "blue") {
              drop[i].classList.add("blue-dropDown-menu");
            } else {
              drop[i].classList.remove("blue-dropDown-menu");
            }
          }
        }
      });
    },
    //点击头像
    clickAvatar() {
      this.$nextTick(() => {
        let drop = document.getElementsByClassName("el-dropdown-menu"); 
        if(drop.length){
          for (let i = 0; i < drop.length; i++) {
            if (local.get("theme") == "blue") {
              drop[i].classList.add("blue-dropDown-menu");
            } else {
              drop[i].classList.remove("blue-dropDown-menu");
            }
          }
        }
      });
    },
    open() {
      this.$confirm("确定退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          let { code, msg } = await api_logout();
          if (code == 200) {
            local.remove('tagArr')
            local.clear();
            local.set("theme", this.theme); //清空缓存后保存上次选择的主题
            location.href = this.getnewurl();
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {});
    },
    jumPersonal() {
      this.$router.push("/personal");
    },
    //汇率入口
    async exchangeRate(){
      await this.getRateCurrent();
      this.dialograteVisible = true;
    },
    handlerateClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
  },
  async created() {
    console.log('messageWarnList',this.messageWarnList.length);
    await this.getRateCurrent();
    this.getCurrencyList();//币种获取
    this.theme = local.get("theme") ? local.get("theme") : 'white';
    let roleNames =local.get("roleNames") ? JSON.parse(local.get("roleNames")) :[];
    if(roleNames && roleNames.includes('财务')){
      this.financeDept = true
    }else{
      this.financeDept = false
    }
    console.log(this.theme);
    if (local.get("tagArr")) {
      this.tagArr = JSON.parse(local.get("tagArr"));
      console.log(this.tagArr);
    }
    this.countBread();
    this.handleClick(this.$router.app._route.meta);
    console.log(this.$router.app._route.meta);
    console.log(this.width);
  },
  mounted() {
    this.$nextTick(() => {
      this.theme = local.get("theme") ? local.get("theme") : 'white';
      let element = document.getElementById("theme-top");
      if (this.theme == "blue") {
        // this.switchthemeValue = true;
        element.classList.add("blue-top-left");
        element.classList.remove("top-left");
      } else {
        // this.switchthemeValue = false;
        element.classList.add("top-left");
        element.classList.remove("blue-top-left");
      }
    });
    window.addEventListener("beforeunload", this.beforeunload);
  },
  beforeDestroy() {
    console.log("页面即将被销毁", this.tagArr);
    local.remove("tagArr");
  },
  // 监听hash值变化，当发生变化时，重新计算面包屑
  watch: {
    '$route':{
      //监听由登录页或者刷新首页进入首页时，弹出消息
      async handler(to, from){
        console.log('当前页面路由:',to);
        //新路由信息
        console.log('上一个路由:',from);//老路由信息
        if(!from && (to && to.path === '/home')){
          await this.getmessagePage(this.currentPage,10,'0')
          //为1：刷新；为0：首次加载
          if(window.performance.navigation.type !== 1){
            if(this.unreadMessageWarnList.length){
              this.clickMessage();
            }
          }
        }
      },
      deep:true,//深度观察监所
      immediate:true,//第一次初始化渲染就可以监听,

    },
    "$route.path"() {
      this.countBread();
      this.handleClick(this.$router.app._route.meta);
    },
    changeRed: {
      handler(val) {
        console.log(val);
        let offWidth = document.getElementById("navA").offsetWidth; //屏幕宽度
        console.log(this.$refs.tabcolor);
        if (!this.$refs.tabcolor[val]) {
          val = this.$refs.tabcolor.length - 1;
        }
        console.log(this.$refs.tabcolor[val]);
        let list = this.$refs.tabcolor[val].offsetWidth; //单个菜单宽度
        let offLeft = this.$refs.tabcolor[val].offsetLeft; //单个菜单距离左侧宽度

        // let width = this.$refs.tabcolor[val].getBoundingClientRect().width;
        //中间值 =( 屏幕宽度 - 导航菜单每一项的宽度 ) / 2;
        let diffWidth = offWidth / 2 - list / 2;
        diffWidth = offLeft + list - offWidth / 2;
        this.$nextTick(() => {
          document.getElementById("navA").scrollLeft = diffWidth;
        });
        console.log(offWidth, list, offLeft, diffWidth);
      }
    },
    "$store.state.screenWidth": function() {
      var myElement = document.getElementById("right-bottom"); // 根据id获取div元素的引用
      var width = myElement.offsetWidth;
      this.width = width;
      console.log("right-bottom宽度:", width);
      // if (store.state.screenWidth < 1550) {
      //   this.width = store.state.screenWidth - 270;
      //   console.log(store.state.screenWidth, this.width);
      // } else {
      //   this.width = store.state.screenWidth - 315;
      // }
    }
  },
};
</script>
  
<style lang="less" scoped>
// @import "../../assets/styles/layout/rightTop.less";
/deep/.el-dialog__body{
  padding-top:0px;
}
.el-divider__text{
  font-size: 16px;
  padding:0 12px;
}
.rate-manage{
  max-height: 360px;
    overflow-y: auto;
.order-rate{
  li{
    display: flex;
    margin-bottom: 12px;
    p{
      margin: 0 8px;
      line-height: 40px;
      display: flex;

      /deep/.el-input__inner{
        width: 150px;
      }
      .rate-input{
        width: 150px;
      }
      .rate-label{
        width: 60px;
        margin-right: 8px;
        text-align: right;
      }
    }
  }
}
}
/deep/.el-dialog__header{
  text-align: left;
}
/deep/.el-dialog__footer{
  text-align: center;
  .el-button {
    font-size: 14px;
    padding: 9px 15px;
    border-radius: 3px;
}
}
.message-set-manage{
  margin-top: 12px;
}
.message-type-title{
  display: flex;
  margin-left: 18px;
  margin-bottom: 12px;
  p{
    max-width: 320px;
    margin-left: 18px;
    text-align: left;
  }
}

.top-left {
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.3);

  .top-head {
    margin-left: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-breadcrumb {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
    }

    .right-menu {
      margin-right: 18px;
      display: flex;
      align-items: center;
      img {
        height: 30px;
      }

      .avatar-container {
        .img-none {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 50%;
          border: 1px solid #4257a5;
          background-color: #4257a5;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }

  .top-bottem {
    width: 100vw;
    height: 62px;
    box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.3);

    /* 固定导航 */
    .fix-nav {
      position: absolute;
      top: 72px;
      left: 324px;
      z-index: 999;
    }

    .tabbar::-webkit-scrollbar {
      //隐藏滑动条
      display: none;
    }

    .tabbar {
      // width: 100vw;
      height: 62px;
      line-height: 62px;
      // background: #ffffff;
      // font-size: 30px;
      font-weight: 500;
      // color: #1d1d1d;
      overflow-x: scroll;
      display: flex;
      justify-content: left;
      align-items: center;
      white-space: nowrap;
    }

    .scroll-pane-box {
      width: 100%;
      height: 62px;
      line-height: 62px;
      overflow-x: scroll;
      display: flex;
      justify-content: left;
      align-items: center;
      white-space: nowrap;
    }

    .bread-list {
      height: 24px;
      border-radius: 0 15px 15px 0px;
      margin-left: 18px;
      padding: 0px 15px;
      color: #fff;
      background-color: #c4c2c1;
    }

    .isactive {
      background-color: #83aac6;
    }

    /deep/.tag-active {
      background-color: #83aac6 !important;
    }

    .tag-active-i::before {
      display: inline-block;
      content: "•";
    }

    .tag-active-close::before {
      display: inline-block;
      content: "×";
    }

    //导航标签
    a {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      border: none;
      color: #fff;
      background-color: #c4c2c1;
      font-size: 16px;
      border-radius: 0 18px 18px 0px;
      padding: 0px 15px;
      margin-right: 18px;
      text-decoration: none;
    }

    /deep/.el-tag__close:hover {
      background-color: #83aac6;
    }

    /deep/.el-tag .el-tag__close {
      color: #fff;
    }

    .el-scrollbar__wrap {
      height: 49px;
    }
  }
}

/deep/.el-pagination{
  text-align: right;
}
//蓝色
.blue-top-left {
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.3);

  .top-head {
    // margin-left: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url(../../assets/blueImgs/index/右顶部面包屑.png) no-repeat;
    background-size: 100% 54px;

    .el-breadcrumb {
      margin-left: 18px;
      height: 54px;
      line-height: 54px;
      font-size: 16px;
    }
    /deep/.el-breadcrumb__inner.is-link {
      color: #fff;
    }
    .right-menu {
      margin-top: 20px;
      margin-right: 30px;
      display: flex;
      align-items: center;
      img {
        height: 30px;
      }

      .avatar-container {
        .img-none {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 50%;
          border: 1px solid #4257a5;
          background-color: #4257a5;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }

  .top-bottem {
    width: 100vw;
    height: 62px;
    box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.3);
    background: url(@/assets/blueImgs/index/右顶导航改.png) no-repeat;
    background-size: 100% 100%;

    /* 固定导航 */
    .fix-nav {
      position: absolute;
      top: 72px;
      left: 324px;
      z-index: 999;
    }

    .tabbar::-webkit-scrollbar {
      //隐藏滑动条
      display: none;
    }

    .tabbar {
      // width: 100vw;
      height: 52px;
      line-height: 52px;
      margin-top: 10px;
      // background: #ffffff;
      // font-size: 30px;
      font-weight: 500;
      // color: #1d1d1d;
      overflow-x: scroll;
      display: flex;
      justify-content: left;
      align-items: center;
      white-space: nowrap;
    }
    .tab_title {
      background: url(@/assets/blueImgs/index/右顶导航块.png) no-repeat;
      background-size: 101% 100%;
      &:last-child {
        // padding-right: 35px;
        background: url(@/assets/blueImgs/index/右顶导航last背景.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .scroll-pane-box {
      width: 100%;
      height: 62px;
      line-height: 62px;
      overflow-x: scroll;
      display: flex;
      justify-content: left;
      align-items: center;
      white-space: nowrap;
    }

    .bread-list {
      height: 24px;
      border-radius: 0 15px 15px 0px;
      margin-left: 18px;
      padding: 0px 15px;
      color: #fff;
      background-color: #398ab4;
    }

    .isactive {
      background-color: #398ab4;
    }

    /deep/.tag-active {
      color: #25cfff;
      // background-color: #398ab4 !important;
    }

    .tag-active-i::before {
      display: inline-block;
      content: "•";
    }

    .tag-active-close::before {
      display: inline-block;
      content: "×";
    }

    //导航标签
    a {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      border: none;
      color: #fff;
      // background-color: #398ab4;
      font-size: 16px;
      border-radius: 0 18px 18px 0px;
      padding: 0px 15px;
      margin-right: 18px;
      text-decoration: none;
    }

    /deep/.el-tag__close:hover {
      background-color: #398ab4;
    }

    /deep/.el-tag .el-tag__close {
      color: #fff;
    }

    .el-scrollbar__wrap {
      height: 49px;
    }
  }
  //弹窗
  /deep/.el-dialog{
    background: #092f6b;
    .el-dialog__title{
      color: #fff;
    }
    .el-divider__text{
      color:#fff;
      background-color: #092f6b;
    }
    .rate-label{
      color:#fff;
    }
    .el-input__inner{
      color: #fff;
      background-color:#092f6b ;
    }
    .el-button--primary {
        background-color: #3876cf;
        border-color: #3876cf;
      }
      .el-button--danger {
        background-color: #b92f74;
        border-color: #b92f74;
      }
      
      //消息模块
      .el-tabs__item{
        color: #fff;
      }
      .el-tabs__item.is-active{
        color: #409EFF;
      }
      .message-list{
        color:#fff;
      }

  }
  //消息模块
  #messageIcon-i{
    color:#fff;
  }

  .message-type-title{
    color: #fff;
    /deep/.el-checkbox__label{
      color: #fff;
    }
  }

  // 分页
  /deep/.el-pagination__total{
    color: #fff;
  }
  /deep/.el-pagination{
    button{
      color: #fff;
      background-color: transparent;
    }
  }
  /deep/.el-pager li{
    color: #fff;
    background-color: transparent;
  }
  /deep/.el-dialog__headerbtn .el-dialog__close{
    color:#fff;
  }
}
//头像个人中心退出列表
.blue-dropDown-menu {
  background-color: #092F6B;
  border:1px solid #398ab4;
  .el-dropdown-menu__item {
    color: #fff;
  }
  .el-dropdown-menu__item--divided:before{
    background-color: #092F6B;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover{
    background-color: #092F6B;
  }
  /deep/.popper__arrow::after{
    border-bottom-color:#092F6B !important;
  }
}


//消息框
#messageIcon-i{
  font-size: 22px;
  margin: 0 12px;
  cursor: pointer;
}
.messageIcon-badge{
  margin-right: 12px;
  #messageIcon-i{
    margin-right: 0px;
  }
}
.my-switch{
  margin-right: 12px;
}


</style>