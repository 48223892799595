import axios from '../utils/req'


//获取全部导航菜单
export var api_getAllMenu = params => axios.post('/v1/system/menu/build', params);

//懒加载菜单
export var api_getLazyMenu = params => axios.post('/v1/system/menu/lazy', params);

//分页菜单
export var api_getMenuPage = params => axios.post('/v1/system/menu/page', params);

//子菜单列表
export var api_getMenuList = params => axios.post('/v1/system/menu/list', params);

//上级目录列表
export var api_getSuperiorMenuList = params => axios.post('/v1/system/menu/superior', params);

//新增菜单
export var api_addMenu = params => axios.post('/v1/system/menu/add', params);

//更新菜单
export var api_updateMenu = params => axios.post('/v1/system/menu/update', params);

//删除菜单
export var api_batchDelMenu = params => axios.post('/v1/system/menu/batch-del', params);

