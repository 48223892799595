<template>
    <div class="table-box" id="messageTable">
      <transition name="tableInfo" appear> 
        <div class="container">
          <el-table
          ref="table"
          :data="tableData"
          border
          :max-height="tableHeight"
          :cell-style="{padding: '0px'}"
          @selection-change="handleSelectionChange"
          :default-sort = "{prop: 'date', order: 'descending'}"
        >
          <!-- 这里是否要添加前面的序号123456 -->
          <!-- <el-table-column label="序号" type="index" width="50" fixed="left"></el-table-column> -->
          <el-table-column
            v-if="orderGoodsOperate"
            type="index"
            width="50"
            fixed="left"
          ></el-table-column>
          <el-table-column v-else type="selection" width="50" fixed="left"></el-table-column>
          <div v-for="(col, index) in cols" :key="index">
            <!-- 这里判断是什么类型 -->
            <template v-if="col.type == 'input'">
              <el-table-column
                sortable
                :scoped-slot="labelHead"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                align="left"
                show-overflow-tooltip
              ></el-table-column>
            </template>
            <template v-else-if="col.type == 'switch'">
              <el-table-column
                :render-header="labelHead"
                :prop="col.prop"
                :label="col.label"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-switch
                    disabled
                    v-model="scope.row[col.prop]"
                    active-color="#6791EF"
                    inactive-color="#ff4949"
                  ></el-switch>
                </template>
              </el-table-column>
            </template>
          </div>
          <!-- 这里可以根据需看有没有需要操作按钮的 -->
          <el-table-column
            :render-header="labelHead"
            v-if="orderMessageOperate"
            fixed="right"
            label=""
            width="50"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="orderMessageLook(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          </el-table>
        </div>
      </transition>
    </div>
  </template>
  
  
  <script>
  import store from "@/store";
  import local from "@/utils/local";
  export default {
    name: "pageTable",
    props: {
      // 接收展示数据
      tableData: {
        type: Array,
        default() {
          return [];
        }
      },
      // 接收配置项的数据
      cols: {
        type: Array,
        default() {
          return [];
        }
      },
      //订单消息
      orderMessageOperate: {
        typeof: Boolean,
        default() {
          return false;
        }
      },
      //查看订单消息
      lookMessageWarn: {
        typeof: Function,
        default() {
          return Function;
        }
      },
      //货物
      orderGoodsOperate: {
        typeof: Boolean,
        default() {
          return false;
        }
      },
      //主题
      theme: {
        typeof: String,
        default() {
          return 'white';
        }
      },
    },
    data() {
      return {
        multipleSelection: [],
        tableHeight: "370",
      };
    },
    created() {
      this.theme = local.get("theme");
    },
    mounted() {
      this.$nextTick(() => {
        var element = document.getElementById("messageTable");
        // -----------------蓝色-------------
        if (this.theme == "blue") {
          element.classList.remove("table-box");
          element.classList.add("blue-table-box");
        } else {
          element.classList.remove("blue-table-box");
          element.classList.add("table-box");
        }
      });
    },
    beforeUpdate() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    methods: {
      labelHead: function(h, { column, $index }) {
        let l = column.label.length;
        let f = 16;
        column.minWidth = f * (l + 2); //加上一个文字长度
        return h("div", { class: "table-head", style: { width: "100%" } }, [
          column.label
        ]);
      },
      orderMessageLook(val) {
        console.log("orderMessageLook:", val);
        this.lookMessageWarn(val)
      },
      handleSelectionChange(val) {
        console.log("复选框选中内容：", val);
        this.$emit("currentChange", val);
        this.multipleSelection = val;
      },
      handleCurrentChange(val) {
        console.log("点击当前行：", val);
        this.$emit("currentChange", val);
      }
    }
  };
  </script>
  <style lang="less" scoped>
  .table-box::-webkit-scrollbar {
    //隐藏滑动条
    display: none;
  }
  .blue-table-box::-webkit-scrollbar {
    //隐藏滑动条
    display: none;
  }
  .table-box {
    height: 100%;
    overflow-y: scroll;
  
    .container {
      height: 100%;
    }
    /deep/.cell.el-tooltip {
      white-space: pre-wrap;
    }
    /deep/.cell {
      padding: 0 5px;
      // text-align: center;
    }
    .el-table {
      // border: 1px solid red;
      width: calc(100% - 35px);
      height: 100%;
      max-height: 100%;
      margin: 0px 18px;
      /deep/.table-head {
        font-size: 16px;
        padding:5px 0px;//表头文字居中显示
      }
      /deep/.el-table__cell {
        font-size: 14px;
      }
      /deep/.el-button--text {
        font-size: 14px;
      }
    }
    /deep/.el-table__body-wrapper {
      max-height: 100%;
      overflow-y: auto;
    }
    // /deep/.el-table__fixed-right {
    //   bottom: 8px !important;
    //   right: 8px !important;
    // }
    //滚动条样式
    /deep/ .el-table__fixed-right {
      right: 0px !important;
      height: 100%;
    }
    /deep/ .el-table__fixed-right .el-table__fixed-body-wrapper {
      height: auto !important;
      bottom: 0px !important;
      max-height: 100% !important;
    }
    /deep/ .el-table__fixed-right-patch {
      width: 8px !important;
    }
    /deep/ .el-table__fixed {
      height: auto !important;
      bottom: 0px !important;
    }
    /deep/ .el-table__fixed .el-table__fixed-body-wrapper {
      height: auto !important;
      bottom: 0px !important;
      max-height: 100% !important;
    }
    /deep/ .el-table--scrollable-x .el-table__fixed {
      height: auto !important;
      bottom: 7px !important;
    }
    /deep/ .el-table--scrollable-x .el-table__fixed-right {
      bottom: 7px !important;
    }
    /deep/ .el-table--scrollable-y .el-table__fixed-right {
      right: 8px !important;
      height: auto !important;
    }
    // /deep/ .el-table--fluid-height .el-table__fixed {
    //   bottom: 8px !important;
    // }
    /deep/.el-table__body tr.current-row > td.el-table__cell,
    .el-table__body tr.selection-row > td.el-table__cell {
      // background-color: #A0ABD2;
    }
    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #a0abd2;
      border-radius: 3px;
    }
  }
  
  /deep/.el-table th.el-table__cell>.cell{
       display: flex;
       font-size: 16px;
       align-items: center;
      }
  ///蓝色
  .blue-table-box {
    height: 100%;
    overflow-y: scroll;
    .container {
      height: 100%;
    }
    /deep/.cell.el-tooltip {
      white-space: pre-wrap;
    }
    /deep/.cell {
      padding: 0 5px;
      // text-align: center;
    }
    .el-table {
      // border: 1px solid red;
      width: calc(100% - 35px);
      height: 100%;
      max-height: 100%;
      margin: 0px 18px;
      background: url(../../assets/blueImgs/index/内容背景.png) no-repeat;
      background-size: 100% 100%;
      border: none;
      /deep/.table-head {
        font-size: 16px;
      }
      /deep/.el-table__cell {
        font-size: 14px;
      }
      /deep/.el-button--text {
        font-size: 14px;
      }
  
      // ---------
      /deep/th {
        color: #fff;
        background-color: transparent;
      }
      /deep/tr {
        color: #fff;
        background-color: transparent;
      }
      /deep/tr.hover-row > td.el-table__cell {
        background-color: transparent;
      }
    }
    /deep/.el-table th.el-table__cell>.cell{
       display: flex; 
       font-size: 16px;
       align-items: center;
      }
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }
    .gutter {
      width: 8px !important;
    }
    //表格外边框线条
    .el-table::before {
      width: 0px;
    }
    .el-table--border::after {
      // width: 0px;
      background-color: #1e62bd;
    }
    /deep/.el-table__fixed::before {
      height: 0px;
    }
    /deep/.el-table__fixed-right::before {
      height: 0px;
    }
    /deep/.el-table--border .el-table__cell {
      border: 1px solid #1e62bd;
      border-left: none;
    }
    //操作最右边的小白竖条
    /deep/.el-table__fixed-right-patch {
      background-color: transparent;
      border-bottom: none;
    }
    /deep/.el-table--border th.el-table__cell.gutter:last-of-type {
      border-bottom: 1px solid #1e62bd;
    }
    /deep/.el-table__body-wrapper {
      max-height: 100%;
      overflow-y: auto;
    }
    /deep/.el-table__fixed-right {
      // background-color: #092f6b;
    }
    /deep/.el-table__header {
      background-color: #092f6b;
    }
    /deep/.el-table__body {
      background-color: #092f6b;
    }
    // /deep/.el-table__cell.gutter {
    //   width: 8px !important;
    // }
    //滚动条样式
    /deep/ .el-table__fixed-right {
      // right: 1px !important;
      height: 99.8%;
    }
    /deep/ .el-table__fixed-right .el-table__fixed-body-wrapper {
      height: auto !important;
      bottom: 0px !important;
      max-height: 100% !important;
    }
    /deep/ .el-table__fixed-right-patch {
      width: 8px !important;
    }
    /deep/ .el-table__fixed {
      height: auto !important;
      bottom: 0px !important;
      border-left: 1px solid #1e62bd;
    }
    /deep/ .el-table__fixed .el-table__fixed-body-wrapper {
      height: auto !important;
      bottom: 0px !important;
      max-height: 100% !important;
    }
    /deep/ .el-table--scrollable-x .el-table__fixed {
      height: calc(100% - 8px) !important;
      bottom: 8cm !important;
    }
    /deep/ .el-table--scrollable-x .el-table__fixed-right {
      bottom: 8px !important;
    }
    /deep/ .el-table--scrollable-y .el-table__fixed-right {
      right: 8px !important;
      height: auto !important;
    }
    // /deep/ .el-table--fluid-height .el-table__fixed {
    //   bottom: 8px !important;
    // }
    /deep/.el-table__body tr.current-row > td.el-table__cell,
    .el-table__body tr.selection-row > td.el-table__cell {
      // background-color: #A0ABD2;
    }
    // 滚动条的宽度
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #a0abd2;
      border-radius: 3px;
    }
    ///当横纵滚动条同时存在时
    /deep/.el-table__body-wrapper::-webkit-scrollbar-corner {
      background-color: #a0abd2;
    }
    /deep/.el-table__cell.gutter {
      width: 8px;
    }
  }
  ///修改顶部name为gutter原本值为17的宽度值
  /deep/ .el-table th.gutter {
    width: 8px; // 表头右侧空白块宽度，与修改的滚动条样式宽度保持一致，否则会造成表头与内容border错乱
  }
  /deep/ .el-table colgroup col[name="gutter"] {
    width: 8px; // gutter列设置宽度，与上保持一致
  }
  /deep/ .el-table__body {
    width: 100% !important; // 内容宽度，设置为100%，最高级
  }
  /deep/.el-scrollbar__wrap::-webkit-scrollbar {
    width: 8px;
    height: 100%;
  }
  
  //下载账单下拉菜单样式
  .downBill{
    border: 1px solid #ccc;
    /deep/.popper__arrow::after{
      border-bottom-color:#ccc;
    }
    .el-dropdown-menu__item--divided{
      border-color: #c5c5c5;
    }
  }
  //蓝色背景样式
  .blue-dropDown-menu {
    background-color: #092F6B;
    border:1px solid #398ab4;
    .el-dropdown-menu__item {
      .el-button{
        color: #fff;
      }
    }
    .el-dropdown-menu__item--divided{
      border-color: #fff;
    }
    .el-dropdown-menu__item--divided:before{
      background-color: #092F6B;
    }
    .el-dropdown-menu__item:not(.is-disabled):hover{
      background-color: #092F6B;
    }
    /deep/.popper__arrow::after{
      border-bottom-color:#092F6B !important;
    }
  }
  
  
  
  .container{
    transition: all 0.5s;
  }
   /* name是动画的名称 */
  @keyframes fadeInDown{
      0% {
        opacity:0;
      }
      50%{
        opacity: 0.5;
      }
      100% {
        opacity:1;
      }
    }
    @keyframes zoomOutDown{
      0% {
        opacity:1;
      }
      50%{
        opacity: 0.5;
      }
      100% {
        opacity:0;
      }
    }
  
  
  /* 右侧布局动画 */
  .tableInfo-enter-active{
    animation: none !important;
  }
  .tableInfo-leave-active {
    /* 定义 退出时的 动画 过渡时间 */
    transition-duration: .4s !important;
    animation: none !important;
  }
  
  .tableInfo-enter-active /deep/.el-table,
  .tableInfo-leave-active /deep/.el-table{
    /* 设置 填充模式   此处的值为 当动画完成后，保持最后一个属性值（在最后一个关键帧中定义） */
    animation-fill-mode: forwards;  
  }
  
  .tableInfo-enter-active /deep/.el-table{
    animation-duration: 0.5s;
    /* 设置 进入自定义动画 */
    animation-name: fadeInDown;   
    /* 执行曲线 */
    animation-timing-function: linear;   
  }
  
  .tableInfo-leave-active /deep/.el-table{
    animation-duration: 0.5s;
    /* 设置  退出 自定义动画 */
    animation-name: zoomOutDown; 
    /* 执行曲线 */
    animation-timing-function: linear;  
  }
  
  
  
  </style>