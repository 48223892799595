<template>
  <div id="theme-layout" class="layout">
    <div class="layout-content">
      <!-- 左边导航 -->
      <LeftMeanu />
      <!-- 右边内容 -->
      <div class="right-box">
        <!-- 右边内容 -->
        <div class="right-top">
          <RightTop />
        </div>
      <transition appear name="rightlayout">
        <div class="right-bottom" id="right-bottom">
          <router-view />
        </div>
      </transition>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMeanu from "@/views/layout/LeftMeanu.vue";
import RightTop from "@/views/layout/RightTop.vue";
import local from "@/utils/local";
// import { api_usersInfo } from "@/api/acc";
export default {
  components: {
    LeftMeanu,
    RightTop
  },
  data() {
    return {
      theme: "white"
    };
  },
  mounted() {
    if (!local.get("theme")) {
      local.set("theme", "white");
    }
    this.theme = local.get("theme");
    this.$nextTick(() => {
      let app = document.getElementById("app");
      let body = document.getElementById("systemBody");
      let element = document.getElementById("theme-layout");
      if (this.theme == "blue") {
        body.classList.add("body-blue");
        app.classList.remove("white");
        app.classList.add("blue");
        element.classList.remove("layout");
        element.classList.add("blue-layout");
      } else {
        body.classList.remove("body-blue");
        app.classList.remove("blue");
        app.classList.add("white");
        element.classList.remove("blue-layout");
        element.classList.add("layout");
      }
    });
  },
  methods: {
    // 获取账号信息函数
    // async getUserInfo(){
    //     let { accountInfo } = await api_usersInfo();
    //     this.userInfo = accountInfo;
    //     local.set('userinfo',JSON.stringify(accountInfo));
    // },
    // 退出页面
    exitLogin() {
      console.log("123");
      local.clear();
      this.$router.push("/login");
      // 重新加载页面
      // location.reload();
    }
  },

};
</script>

<style lang="less" scoped>
// @import "../../assets/styles/layout/layout.less";
//白色
.layout {
  width: 100%;
  height: 97%;

  .layout-content {
    width: 100%;
    height: 100%;
    display: flex;

    .right-box {
      width: calc(100% - 290px);
      min-width: 700px;
      height: 100%;
      margin-top: 18px;
      margin-right: 18px;
    }

    .right-top {
      height: 116px;
      background-color: #fff;
    }

    .right-bottom {
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
      height: calc(100% - 125px);
      background-color: #fff;
    }
  }
}
//蓝色
.blue-layout {
  width: 100%;
  height: 97%;

  .layout-content {
    width: 100%;
    height: 100%;
    display: flex;

    .right-box {
      width: calc(100% - 290px);
      min-width: 700px;
      height: 100%;
      margin-top: 18px;
      margin-right: 18px;
      background: url(@/assets/blueImgs/index/右侧背景.png) no-repeat;
      background-size: 100% 100%;
    }

    .right-top {
      height: 116px;
      // background-color: #fff;
    }

    .right-bottom {
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
      height: calc(100% - 125px);
      // background-color: #fff;
    }
  }
}





.right-bottom{
  transition: all 1s;
}
 /* name是动画的名称 */
@keyframes fadeInDown{
    0% {
      opacity:0;
    }
    50%{
      opacity: 0.5;
    }
    100% {
      opacity:1;
    }
  }
  @keyframes zoomOutDown{
    0% {
      opacity:1;
    }
    50%{
      opacity: 0.5;
    }
    100% {
      opacity:0;
    }
  }


/* 右侧布局动画 */
.rightlayout-enter-active{
  animation: none !important;
}
.rightlayout-leave-active {
  /* 定义 退出时的 动画 过渡时间 */
  transition-duration: .4s !important;
  animation: none !important;
}

.rightlayout-enter-active .mag-user,
.rightlayout-leave-active .mag-user{
  /* 设置 填充模式   此处的值为 当动画完成后，保持最后一个属性值（在最后一个关键帧中定义） */
  animation-fill-mode: forwards;  
}

.rightlayout-enter-active .mag-user{
  animation-duration: 1s;
  /* 设置 进入自定义动画 */
  animation-name: fadeInDown;   
  /* 执行曲线 */
  animation-timing-function: linear;   
}

.rightlayout-leave-active .mag-user{
  animation-duration: 1s;
  /* 设置  退出 自定义动画 */
  animation-name: zoomOutDown; 
  /* 执行曲线 */
  animation-timing-function: linear;  
}

</style>
