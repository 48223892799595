

export default {
  //数组对象去重
  ArrayObjectDeduplication(array, type) {
    console.log(type);
    const map = new Map(array.map(item => [item[type], item]));
    console.log(map);
    const uniques = [...map.values()]
    console.log('uniques:', uniques);
    return uniques;
  },
  countCharOccurrences(str, char) {
    var regex = new RegExp(char, "g"); // 创建全局匹配模式的正则表达式
    return str.match(regex).length; // 返回匹配到的结果数组长度作为字符出现的次数
  },
  //数组去重
  deduplication(arr) {
    if (!Array.isArray(arr)) {
      return;
    }
    let array = [];
    for (let i = 0; i < arr.length; i++) {
      if (array.indexOf(arr[i]) === -1) {
        array.push(arr[i]);
      }
    }
    return array;
  },
  //数组对象过滤返回数组
  filterArray(array, field) {
    let temp = []
    array.forEach(item => {
      if (item[field]) {
        temp.push(item)
      }
    });
    return temp
  },
  //数组对象交换位置
  swap(arr, index1, index2) {
    [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    return arr
  },
  //数组对象排序
  sortByKey(array, key, order) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key]
      //true:升序
      if (order) {
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      } else {
        return ((x < y) ? ((x > y) ? 1 : 0) : -1)
      }
    })
  },
  //判断数组对象中的val字段值是否重复
  checkRepeat(arr, val) {
    var array = [];
    arr.forEach(item => {
      if (item) {
        array.push(item[val]);
      }
    });
    if (new Set(array).size != arr.length) {
      // console.log("数组有重复值");
      return true;
    }
    return false;
  },
  //获取两个日期间隔天数
  getDifferTime(startDate, endDate) {
    let startTime = new Date(Date.parse(startDate.replace(/-/g, "/"))).getTime();
    let endTime = new Date(Date.parse(endDate.replace(/-/g, "/"))).getTime();
    let dates = Math.abs((startTime - endTime)) / (1000 * 60 * 60 * 24);
    return dates;
  },
  // console.log(method.getDifferTime('2024-03-28','2024-04-2'));

  //截取日期格式
  interceptDate(data) {
    if (data && data.indexOf("T") != -1) {
      data = data.substring(
        0,
        data.indexOf("T")
      );
    }
    return data;
  },

  //根据币种返回对应的汇率值
  returnRate(currency) {
    let rate = "";
    let rateList = localStorage.getItem('rate') ? JSON.parse(localStorage.getItem('rate')) : []
    rateList.forEach(item => {
      if (item.currency == currency) {
        rate = item.rate;
      }
    });
    console.log(rate,'当前汇率');
    return rate;
  },

  //判断费用项不能为空
  chargeNameIsNull(arr,str){
    if(arr.length){
      for (let i = 0; i < arr.length; i++) {
        // debugger
        if(arr[i].unitPrice !== '0'){
          if(!arr[i][str]){
            return true
          }
        }else{
          return false
        }
      }
    }
    return false
  },

  //优化JSON.stringify()
  myStringify(arr){
    let aStr = []
    arr.forEach(o =>{
      aStr.push('{"chargeName":"'+o.chargeName+'","currency":'+o.currency+'","index":'+o.index+'","num":'+o.num+'","price":'+o.price+'","rate":'+o.rate+'","unitPrice":'+o.unitPrice+',"remark":'+o.remark+'}') ;
    });
    let str = '['+aStr.join(',')+']';
    console.log(str,'str====优化JSON.stringify()====');
    return str
  }
}