<template>
  <div id="theme-menu" class="left-meanu">
    <div class="title">
      <img src="../../assets/imgs/indexPng/公司logo.png" />

      <div class="title-text" @click="jumpIndex()">首页</div>
    </div>
    <div class="menu-box">
      <keep-alive>
        <!-- active-text-color="#4257A5" -->
        <el-menu
          ref="menu"
          router
          unique-opened
          :active-text-color="theme == 'blue' ? '#25CFFF' :'#4257A5'"
          :default-active="$route.path"
          class="el-menu-vertical"
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          v-pReClickMenu
        >
          <template v-for="(item) in menu">
            <!-- 只有一级路由 -->
            <!-- :class="isOpen ? 'isOpen' : 'offLi'" -->
            <el-menu-item
              :id="item.path"
              v-if="item.children.length == 0"
              :key="item.name"
              :index="item.path"
            >
              <!-- <img :src="currentOpenMenu.key == item.path ? item.meta.Aicon : item.meta.icon" /> -->
              <span
                :class="currentOpenMenu.key == item.path ? 'firstActiveAicon' : 'firstOrdinaryIcon'"
              >
                <i :class="item.meta.icon"></i>
              </span>
              <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>

            <!-- 含有二级路由 -->
            <el-submenu
              v-if="item.children.length > 0"
              :key="item.name"
              :index="item.path"
              :id="item.path"
            >
              <template slot="title">
                <!-- <img :src="chooseIcon(item, $route.path)" /> -->
                <!-- <img :src="currentOpenMenu.key == item.path ? item.meta.Aicon : item.meta.icon" /> -->
                <span
                  :class="currentOpenMenu.key == item.path ? 'firstActiveAicon' : 'firstOrdinaryIcon'"
                >
                  <i :class="item.meta.icon"></i>
                </span>
                <span>{{item.meta.title}}</span>
              </template>
              <div class="clear-both"></div>
              <el-menu-item
                v-for="(v) in item.children"
                :key="v.name"
                :index="item.path + '/' + v.path"
                :class="isOpen ? 'isOpen' : 'offLi'"
                :id="item.path + '/' + v.path"
              >
                <template slot="title">
                  <!-- <img
                    :src="currentSelectMenu == item.path+'/'+v.path ? v.meta.Aicon : v.meta.icon"
                  />-->
                  <span
                    :class="currentSelectMenu == item.path+'/'+v.path ? 'secondActiveAicon' : 'secondOrdinaryIcon'"
                  >
                    <i :class="v.meta.icon"></i>
                  </span>
                  <span>{{ v.meta.title }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import { api_getAllMenu } from "@/apis/menu";
export default {
  data() {
    return {
      menu: [],
      isOpen: false,
      isActive: false,
      currentOpenMenu: { key: "", keyPath: [] },
      currentSelectMenu: "",
      theme: ""
    };
  },
  destroyed(){
    window.removeEventListener('setItemEvent',function(event) {})
  },
  mounted() {
    this.theme = local.get("theme");
    this.$nextTick(() => {
      let element = document.getElementById("theme-menu");
      if (this.theme == "blue") {
        element.classList.add("blue-left-meanu");
        element.classList.remove("left-meanu");
      } else {
        element.classList.add("left-meanu");
        element.classList.remove("blue-left-meanu");
      }
      // document.onkeydown = function(e) {
      //   if (e.which === 27) {
      //     console.log(e);
      //     let messageBox = document.getElementsByClassName('el-message-box');
      //     console.log('messageBox:',messageBox);
      //   }
      // };
    });
    let _that = this;
    // let clickCount = 0;

    // window.addEventListener("click", function() {
    //   clickCount++;
    //   console.log('this:',this);
    //   console.log("屏幕被点击了 " + clickCount + " 次");
    // });
    // window.addEventListener('mousedown',this.MovementTrigger)
    // window.addEventListener('dblclick',this.MovementTrigger)
    // 监听 localStorage 变化
    window.addEventListener('setItemEvent', function(event) {
      console.log('myKey value changed to:', event);
      console.log(this);
      if (event.key === 'theme') {
        _that.theme = event.value
        console.log(_that.theme,'_that.theme');
        // _that.$forceUpdate();
      }
    });
  },
  created() {
    this.getAllMenu();
    if (window.name == "") {
      window.name = "isReload";
    } else if (window.name == "isReload") {
      console.log("页面被重新加载", this);
      let routeArr = this.$route.path.split("/");
      this.handleSelect(`/${routeArr[1]}`, [`/${routeArr[1]}`]);
    }
    

  },
  methods: {
    // MovementTrigger(e) {
    //   console.log(new Date().getTime());
    //     //事件触发时给x y 赋值
    //     console.log(e.clientX,e.clientY);
    // },
    //获取所有菜单信息
    async getAllMenu() {
      let { code, msg, data } = await api_getAllMenu();
      if (code == 200) {
        this.$store.commit("getAllMenuInfo", data);
        let menu = [];
        console.log(data);
        data.forEach(item => {
          let obj = {
            path: "",
            meta: { title: "", icon: "", Aicon: "" },
            children: []
          };
          obj.path = item.path;
          if (!item.meta) {
            item.name = item.children[0].meta.title;
            obj.meta.title = item.children[0].meta.title;
          } else {
            obj.meta.title = item.meta.title;
          }
          switch (item.path) {
            case "/system":
              obj.meta.icon = "iconfont icon-xitong";
              break;
            case "/basic":
              obj.meta.icon = "iconfont icon-jichushuju";
              break;
            case "/order":
              obj.meta.icon = "iconfont icon-menu-yunyingguanli";
              break;
            case "/finance":
              // obj.meta.icon = "iconfont icon-benefit_8510877";
              obj.meta.icon = "iconfont icon-weixuanzhong";
              break;

            default:
              break;
          }
          if (item.children && item.children) {
            item.children.forEach(child => {
              let childObj = {
                meta: {
                  title: "",
                  icon: "",
                  Aicon: ""
                },
                path: ""
              };
              childObj.path = child.path;
              childObj.meta.title = child.meta.title;
              switch (child.path) {
                case "user":
                  childObj.meta.icon = "iconfont icon-yonghuguanli1";
                  break;
                case "role":
                  childObj.meta.icon = "iconfont icon-jueseguanli";
                  break;
                case "department":
                  childObj.meta.icon = "iconfont icon-bumenguanli";
                  break;
                case "menu":
                  childObj.meta.icon = "iconfont icon-icon_caidanguanli";
                  break;
                case "customer":
                  childObj.meta.icon = "iconfont icon-kehuguanli15x";
                  break;
                case "contract":
                  childObj.meta.icon = "iconfont icon-hetongguanli";
                  break;
                case "supplier":
                  childObj.meta.icon = "iconfont icon-gongyingshangguanli";
                  break;
                case "leader":
                  childObj.meta.icon = "iconfont icon-bumenfuzeren";
                  break;
                case "specification":
                  childObj.meta.icon = "iconfont icon-shangpin_guigeguanli";
                  break;
                case "order":
                  childObj.meta.icon = "iconfont icon-dingdanguanli";
                  break;
                case "errorOrder":
                  childObj.meta.icon = "iconfont icon-a-icon_yichangdan";
                  break;
                case "goods":
                  childObj.meta.icon = "iconfont icon-icon-huowuguanli";
                  break;
                case "receipt":
                  childObj.meta.icon =
                    "iconfont icon-caiwuguanli_shoukuanguanli";
                  break;
                case "payment":
                  childObj.meta.icon = "iconfont icon-fukuanguanli1";
                  break;
                case "income":
                  childObj.meta.icon = "iconfont icon-shouru";
                  break;
                case "cost":
                  childObj.meta.icon =
                    "iconfont icon-caiwuguanli-chengbenguanli";
                  break;
                case "statement":
                  childObj.meta.icon = "iconfont icon-duizhangdanguanli";
                  break;
                case "invoice":
                  childObj.meta.icon = "iconfont icon-kaipiao";
                  break;
                case "Invoice":
                  childObj.meta.icon = "iconfont icon-kaipiao";
                  break;
                case "forwarding":
                  childObj.meta.icon = "iconfont icon-ipr_weituoshu";
                  break;
                case "template":
                  childObj.meta.icon = "iconfont icon-xiaoximobanguanli";
                  break;

                default:
                  break;
              }
              if (!item.meta) {
                obj.children = [];
              } else {
                obj.children.push(childObj);
              }
            });
          }
          menu.push(obj);
        });
        console.log(menu);
        this.menu = menu;
      } else {
        this.$message.error(msg);
      }
    },
    handleOpen(key, keyPath) {
      this.currentOpenMenu.key = key;
      this.currentOpenMenu.keyPath = keyPath;
      console.log("开：", key, keyPath, this.currentOpenMenu);
    },
    handleClose(key, keyPath) {
      // debugger
      console.log("关闭==", key, keyPath);
      this.currentOpenMenu = { key: "", keyPath: [] };
    },
    jumpIndex() {
      setTimeout(() => {
        this.$router.push("/home");
      }, 500);
    },
    handleSelect(key, keyPath) {
      console.log("选择导航菜单", key, keyPath);
      this.currentSelectMenu = this.$route.path;
      if (keyPath.length > 1) {
        key = keyPath[0];
      }
      this.currentOpenMenu.key = key;
      this.currentOpenMenu.keyPath = keyPath;
      for (let i = 0; i < this.menu.length; i++) {
        console.log("this.menu[i].path:", this.menu[i].path);
        if (key == this.menu[i].path) {
          this.isOpen = true;
          console.log("isOpen:", this.isOpen);
          return;
        } else {
          this.isOpen = false;
          this.$refs.menu.close(this.menu[i].path);
          console.log("isOpen:", this.isOpen);
        }
      }
    },
    chooseIcon(item, route) {
      let arr = route.split("/");
      console.log("选择激活菜单:", item, route);
      let n = 0; // 用于判断当前一级菜单下的二级菜单是否被点击
      if (item.children != undefined) {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].path == arr[arr.length - 1]) {
            n = 1;
          }
        }
        console.log("n:", n);
        if (n == 1) {
          console.log("222222", item, item.meta.Aicon);
          // 被点击了，返回高亮图标
          return item.meta.Aicon;
        } else {
          // 未被点击，返回未高亮图标
          return item.meta.icon;
        }
      }
    }
  },
  // 监听hash值变化
  watch: {
    "$route.path"() {
      if (this.$route.path == "/home") {
        // document.referrer
        console.log(this.currentOpenMenu.key);
        console.log(window);
        this.$refs.menu.close(this.currentOpenMenu.key);
      }
      let temp = this.$route.path.split("/");
      console.log(temp);
      if (temp[1]) {
        this.currentOpenMenu.key = `/${temp[1]}`;
      }
      this.currentSelectMenu = this.$route.path;
      console.log(this.$route.path);
    },
  }
};
</script>

<style lang="less" scoped>
.left-meanu {
  width: 270px;
  min-width: 240px;
  height: calc(100% - 9px);
  background: #fff;
  margin: 18px;
  border-radius: 15px;

  .title {
    height: 54px;
    padding: 10px 0px 0px 10px;
    display: flex;

    img {
      width: 31px;
      height: 24px;
    }
    .title-text {
      font-size: 18px;
      margin-left: 10px;
      color: #26357d;
      cursor: pointer;
    }
  }

  .iconfont {
    margin-right: 8px;
    font-size: 24px;
  }

  .menu-box {
    width: 240px;
    padding: 0px 10px;
    margin-left: calc(50% - 120px);
  }

  .el-menu {
    border: 0;
    font-size: 16px;

    .el-submenu {
      border-radius: 8px;
    }

    .el-menu-item,
    /deep/.el-submenu__title {
      font-size: 16px;
      padding-left: 10px;
      text-align: left;
      color: #4257a5;

      img {
        width: 25px;
        margin-right: 15px;
      }

      .el-icon-menu {
        width: 27px;
        height: 27px;
      }

      .el-icon-menu:before {
        visibility: hidden;
      }
    }
  }
  ///解决关闭二级菜单时出现的一下白色背景
  /deep/.el-menu--inline {
    // overflow: visible !important;
    background-color: #7b8ac0;
  }

  /deep/.is-opened {
    background-color: #4257a5;

    /deep/.el-submenu__title {
      color: #fff !important;
      background-color: #7b8ac0;
    }

    /deep/.el-menu-item {
      color: #fff;
      background-color: #7b8ac0;
    }
  }

  .is-opened /deep/.el-menu {
    background-color: #7b8ac0;
  }

  .is-opened /deep/.el-submenu__title {
    color: #fff;
  }

  .is-opened /deep/.el-menu-item {
    color: #fff;
  }

  .is-opened /deep/.el-menu {
    border-radius: 0px 0px 8px 8px;
  }

  .is-opened /deep/.el-menu .el-menu-item:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  /deep/.el-submenu__title:hover {
    border-radius: 8px 8px 0px 0px;
    // background-color: #4257a5;
    background-color: #fff;
    // color: #fff !important;
  }

  .is-opened /deep/.el-submenu__title:hover {
    border-radius: 8px 8px 0px 0px;
    background-color: #4257a5 !important;
    color: #fff !important;
  }

  /deep/.el-menu-item:hover {
    background-color: #7b8ac0;
    // color: #4257a5 !important;
  }

  .el-submenu .el-menu-item {
    min-width: 10px;
  }

  .isOpen {
    //background-color: #7b8ac0;
  }

  /deep/.el-submenu .el-menu-item.is-active {
    background-color: #7b8ac0;
  }

  .firstOrdinaryIcon {
    line-height: 17px;
    i {
      color: #26357d;
    }
  }
  .firstActiveAicon {
    line-height: 17px;
    i {
      color: #fff;
    }
  }
  .secondOrdinaryIcon {
    line-height: 17px;
    i {
      color: #fff;
    }
  }
  .secondActiveAicon {
    line-height: 17px;
    i {
      color: #26357d;
    }
  }
}
//蓝色
.blue-left-meanu {
  width: 270px;
  min-width: 270px;
  // height: calc(100% - 9px);
  height: 100%;
  background: url(@/assets/blueImgs/index/左侧导航.png) no-repeat;
  background-size: 100% 100%;
  margin: 18px;
  border-radius: 15px;
  .title {
    height: 50px;
    margin-top: 20px;
    padding: 10px 0px 0px 18px;
    display: flex;
    img {
      width: 31px;
      height: 24px;
    }
    .title-text {
      font-size: 18px;
      margin-left: 10px;
      color: #60dcff;
      cursor: pointer;
    }
  }
  .iconfont {
    margin-right: 8px;
    font-size: 24px;
  }
  .menu-box {
    width: 240px;
    padding: 0px 15px;
    margin-left: calc(50% - 120px);
  }
  .el-menu {
    border: 0;
    font-size: 16px;
    background-color: transparent;
    .el-submenu {
      position: relative;
      // border-radius: 8px;
      margin-bottom: 10px;
      background: url(@/assets/blueImgs/index/一级菜单背景.png) no-repeat;
      background-size: contain;
    }
    .el-menu-item,
    /deep/.el-submenu__title {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      padding-left: 10px;
      text-align: left;
      color: #fff;
      img {
        width: 25px;
        margin-right: 15px;
      }
      .el-icon-menu {
        width: 27px;
        height: 27px;
      }
      .el-icon-menu:before {
        visibility: hidden;
      }
    }
  }
  /deep/.el-menu--inline {
    // overflow: visible !important;
    background-color: #143d79;
  }
  /deep/.is-opened {
    background-color: transparent;
    /deep/.el-submenu__title {
      color: #fff !important;
      background-color: transparent;
    }
    /deep/.el-menu-item {
      color: #fff;
      background-color: transparent;
    }
  }
  .clear-both {
    position: absolute;
    top: -12px;
    width: 100%;
    height: calc(100% + 12px);
    background: url(@/assets/blueImgs/index/二级菜单背景.png) no-repeat;
    background-size: 100% 100%;
    background-position-y: 0px;
  }
  .is-opened /deep/.el-menu {
    background-color: transparent;
  }
  .is-opened /deep/.el-submenu__title {
    color: #fff;
  }
  /deep/.el-submenu__title:hover {
    background-color: transparent;
  }
  .is-opened /deep/.el-submenu__title:hover {
    background-color: transparent !important;
  }
  /deep/.el-menu-item:hover {
    background-color: transparent !important;
    //   // color: #4257a5 !important;
  }
  .el-submenu .el-menu-item {
    min-width: 10px;
  }
  .isOpen {
    // background-color: #7b8ac0;
  }
  //有白背景出现，随便点哪自动消失
  /deep/.el-submenu .el-menu-item.is-active {
    // background-color: #7b8ac0;
    background-color: transparent;
  }

  .firstOrdinaryIcon {
    line-height: 17px;
    i {
      color: #fff;
    }
  }
  .firstActiveAicon {
    line-height: 17px;
    i {
      color: #fff;
    }
  }
  .secondOrdinaryIcon {
    line-height: 17px;
    i {
      color: #fff;
    }
  }
  .secondActiveAicon {
    line-height: 17px;
    i {
      color: #60dcff;
    }
  }
}
</style>