
import axios from '../../utils/req'


//获取分页信息
export var api_getdictPage = params => axios.post('/v1/system/dict/page', params);

//获取查询字典信息
export var api_getdictAll = params => axios.post('/v1/system/dict/all', params);

//获取详情
export var api_getdictDetail = params => axios.get('/v1/system/dict-detail/dict-detail', params);

//获取多个详情
export var api_getdictDetailMap = (dictName,params) => axios.post(`/v1/system/dict-detail/map?dictName=${dictName}`, params);

//新增
export var api_addDict = (params) => axios.post('/v1/system/dict/add', params);

//新增
export var api_addDictDetail = (params) => axios.post('/v1/system/dict-detail/add', params);

//修改
export var api_updateDict = (params) => axios.post('/v1/system/dict/update', params);

//修改
export var api_updateDictDetail = (params) => axios.post('/v1/system/dict-detail/update', params);

//删除
export var api_delDictDetail = (params) => axios.post('/v1/system/dict-detail/del', params);

//删除
export var api_delDict = (params) => axios.post('/v1/system/dict/batch-del', params);
