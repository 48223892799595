import axios from '../utils/req'

//查看消息分页
export var api_getMessageWarnPage = params => axios.post('/v1/general/message-warn/page', params);

//查看消息
export var api_messageWarnView = params => axios.post('/v1/general/message-warn/view', params);

//新增消息
export var api_addmessageWarn = params => axios.post('/v1/general/message-warn/add', params);

//批量已读
export var api_messageWarnbatchBiew = params => axios.post('/v1/general/message-warn/batch-view', params);


//消息订阅列表
export var api_messageSubscribeList = params => axios.post('/v1/general/message-subscribe/list', params);

//消息订阅分页
export var api_messageSubscribePage = params => axios.post('/v1/general/message-subscribe/page', params);

//新增消息订阅
export var api_addmessageSubscribe = params => axios.post('/v1/general/message-subscribe/add', params);

//更新消息订阅
export var api_updatemessageSubscribe = params => axios.post('/v1/general/message-subscribe/update', params);

//消息模板列表
export var api_messageTemplateList = params => axios.post('/v1/general/message-template/list', params);

//消息模板分页
export var api_messageTemplatePage = params => axios.post('/v1/general/message-template/page', params);

//消息算法列表
export var api_messageArithmeticList = params => axios.post('/v1/general/message-arithmetic/list', params);


//新增消息模板
export var api_addmessageTemplate = params => axios.post('/v1/general/message-template/add', params);

//更新消息模板
export var api_updatemessageTemplate = params => axios.post('/v1/general/message-template/update', params);

//删除消息模板
export var api_batchDelmessageTemplate = params => axios.post('/v1/general/message-template/batch-del-logically', params);

