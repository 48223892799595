/*
    封装本地存储
*/

export default {
    // 存
    set(k,val){
        return localStorage.setItem(k,val);
    },
    // 取
    get(k){
        return localStorage.getItem(k);
    },
    // 删除单条数据
    remove(k){
        return localStorage.removeItem(k);
    },
    // 清空数据
    clear(){
        return localStorage.clear();
    }
}
