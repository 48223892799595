var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mySwitchWrap",class:[_vm.disabled ? 'disabledSwitch' : '', _vm.size],on:{"click":_vm.changeStatus}},[_c('input',{ref:"input",staticClass:"switchInput",attrs:{"type":"checkbox","true-value":_vm.activeValue,"false-value":_vm.inactiveValue,"disabled":_vm.disabled},on:{"change":_vm.changeStatus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changeStatus.apply(null, arguments)}}}),_c('span',{class:[
      'switchCentre',
      'circleDotLeft',
      _vm.isOpen ? 'changeCircleDotRight' : '' ],style:({
      background: _vm.computedBackground,
      borderColor: _vm.computedBackground,
    })},[_c('span',{staticClass:"text",style:({
        justifyContent: _vm.isOpen ? 'flex-start' : 'flex-end',
        padding: _vm.isOpen ? '0 20px 0 8px' : '0 8px 0 20px',
      })},[_vm._v(_vm._s(_vm.isOpen ? _vm.openText : _vm.closeText))])])])}
var staticRenderFns = []

export { render, staticRenderFns }