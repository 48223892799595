import axios from '../utils/req'


//获取oss  token
// export var geToken = () => axios.post('/v1/oss/get-token')

//获取验证码图片
export var getCodeImg = params => axios.get('/v1/auth/code', params);
//登录
export var api_login = params => axios.post('/v1/auth/login', params);
//退出登录
export var api_logout = params => axios.post('/v1/auth/logout', params);

export var getFeishu = params => axios.get('https://open.feishu.cn/open-apis/authen/v1/authorize', params);

//汇率获取
export var api_rateCurrent = params => axios.post('/v1/system/rate/info/current', params);

//汇率新增
export var api_addRate = params => axios.post('/v1/system/rate/info/add', params);

//汇率修改
export var api_updateRate = params => axios.post('/v1/system/rate/info/update', params);