// 列表显示分类字典(得label)
export function selectClassFlyDict(datas, value) {
    var actions = [];
    Object.keys(datas).some((key) => {
        if (datas[key].value == ('' + value)) {
            actions.push(datas[key].label);
            return true;
        }
    })
    return actions.join('');
}
// 列表弹窗分类字典(得数值)
export function selectNumClassFlyDict(datas, value) {
    var actions = [];
    Object.keys(datas).some((key) => {
        if (datas[key].label == ('' + value)) {
            actions.push(datas[key].value);
            return true;
        }
    })
    return actions.join('');
}