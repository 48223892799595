import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 重置样式表
import './assets/styles/reset.css'
//引入字体图标css文件
import '@/assets/fonts/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/animation.css'


//点击遮罩层不关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.use(ElementUI);

//输入框限制输入内容
import inputDirective from './utils/input/install';
Vue.use(inputDirective);

import { selectClassFlyDict, selectNumClassFlyDict } from "@/utils/dictionary";
// 全局方法挂载
Vue.prototype.selectClassFlyDict = selectClassFlyDict
Vue.prototype.selectNumClassFlyDict = selectNumClassFlyDict

import storage from './utils/tool';
Vue.use(storage);



import preventReClick from './utils/preventReClick'
Vue.use(preventReClick);
//左侧菜单防止点击过快，同时触发open和close
import pReClickMenu from './utils/pReClickMenu'
Vue.use(pReClickMenu);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

