import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    roleCodes: [],//用户权限
    menuInfo: [],//所有菜单信息
    screenWidth: 1200,
    menu: [],
  },
  getters: {
    getMenu(state) {
      return state.menu
    }
  },
  mutations: {
    setMenu(state, meun) {
      state.menu = meun
    },
    roleCodesFun(state, new_value) {
      console.log(state);
      console.log(new_value);
      state.roleCodes = new_value;
    },
    getAllMenuInfo(state, new_value) {
      console.log(state);
      console.log(new_value);
      state.menuInfo = new_value;
    },
    screenWidthFun(state, new_value) {
      console.log(state);
      console.log(new_value);
      state.screenWidth = new_value;
    },

  },
  actions: {
  },
  modules: {
  }
})
