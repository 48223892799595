import { api_getAllMenu } from '@/apis/menu'
import VueRouter from 'vue-router'
import router from './routers'
import Layout from '../views/layout/Layout.vue'
// 引入vuex仓库
import store from '@/store'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}//解决触发同一路由报错

function page(first, second) {
    return () => import(`@/views/layout${first}/${second}.vue`)
}

const whiteRouter = ['/', '/login', '/auth'];

// 定义变量接收api的路由数据
let mRoutes; let menu = []
router.beforeEach(async (to, form, next) => {
    if (whiteRouter.indexOf(to.path) != -1) {
        console.log("白名单")
        next()
        return
    }

    if (!mRoutes) {
        let res = await api_getAllMenu();
        mRoutes = res.data
        menu = []
        console.log(res,mRoutes);
        if(!mRoutes) return
        mRoutes.forEach(item => {
            let obj = {};
            obj.path = item.path;
            obj.meta = item.meta;
            obj.component = Layout;
            obj.children = [{
                meta: item.meta,
                path: 'index',
                component: () => import('@/views/layout/Index.vue')
            }]
            obj.redirect = `${item.path}/index`;
            if (item.children && item.children.length) {
                item.children.forEach(vtem => {
                    let childObj = {};
                    childObj.meta = vtem.meta;
                    childObj.path = vtem.path;
                    childObj.component = page(item.path, vtem.path.charAt(0).toUpperCase() + vtem.path.slice(1));
                    obj.children.push(childObj)
                });
            }
            menu.push(obj)
        });
        console.log('menu====', menu);
        menu.push({ path: '*', redirect: '/404' })
        // 向仓库发送api的路由数据
        store.commit('setMenu', menu)
        router.addRoutes(menu)
        console.log(router.getRoutes(), '打印路由1')
        next({ ...to })
    } else {
        console.log(mRoutes, '打印路由2')
    }
    next()
})

export default router