


import axios from '../../utils/req'


//获取列表分页信息
export var api_getorderList = params => axios.post('/v1/order/info/page', params);

//获取列表信息
export var api_getorderAllList = params => axios.post('/v1/order/info/list', params);
export var api_getorderAllNoList = params => axios.post('/v1/order/info/all-list', params);

//订单详情
export var api_orderInfoList = params => axios.post('/v1/order/info', params);

//新增信息
export var api_addorderList = params => axios.post('/v1/order/info/add', params);

//更新信息
export var api_updateorderList = params => axios.post('/v1/order/info/update', params);

//删除信息
export var api_delorderList = params => axios.post('/v1/order/info/batch-del-logically', params);

//获取地址信息
export var api_orderaddressList = params => axios.post('/v1/order/address/info/list', params);


//获取货物信息
export var api_orderGoodsList = params => axios.post('/v1/order/goods/info/page', params);

//获取线路地点信息
export var api_orderLineInfo = params => axios.post('/v1/order/address/info', params);

//生成对账单
export var api_createStatement = params => axios.post('/v1/order/info/create-statement', params);

//重复生成对账单
export var api_recreateStatement = params => axios.post('/v1/order/info/re-create-statement', params);

//客户账单客户列表
export var api_getOrderCustomer = params => axios.post('/v1/order/info/get-customer', params);

// //下载客户账单
// export var api_downLoadCustomerOrder = params => axios.post('/v1/order/info/get-customer-order', params);

// //导出订单
// export var api_downLoadOrder = params => axios.post('/v1/order/info/download', params);

// //读取excel里的内容（货物信息）
// export var api_goodsReadExcel = params => axios.post('/v1/order/goods/info/read-excel', params);


//生成订单绑定码
export var getOrderCode = params => axios.get('/v1/order/info/get-code', params);


