import Vue from 'vue'
// 自定义指令防止按钮重复点击 v-preventReClick
export default {
    install(Vue) {
        // 防止重复点击
        Vue.directive('preventReClick', {
            inserted: function (el, binding) {
                el.addEventListener('click', () => {
                    if (el.style['pointer-events'] != 'none') {
                        el.style['pointer-events'] = 'none';
                        setTimeout(() => {
                            el.style['pointer-events'] = 'all';
                        }, binding.value || 3000)
                    }
                    if (!el.disabled) {
                      el.disabled = true
                      setTimeout(() => {
                        el.disabled = false
                      }, binding.value || 3000)
                    }
                })
            }
        })
    }
}
